<template>
<transition name="fade">
  <button class="totop" @click="toTop()" v-show="scY > 300 || $isMobile()"><img src="../assets/icons/arrow_up.svg" alt=""></button>
</transition>
</template>

<script>
export default {
  name: 'toContact',
  data () {
    return {
      scTimer: 0,
      scY: 0
    }
  },
  mounted: function () {
    if (!this.$isMobile()) window.addEventListener('scroll', this.handleScroll)
    // window.AOS.init()
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.totop{
  position: fixed;
  right: 14px;
  bottom: 14px;
  z-index: 9;
  background-color: transparent;
  border: none;
  padding: 0;
  img{
    width: 40px;
    height: 40px;
  }
  // !Mobile
  @include mq-max(){
    position: inherit;
    display: block;
    width: 100%;
    text-align: center;
  }
}
</style>
