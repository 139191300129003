import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import globalMixins from '../mixins'
// import { setTitle, setDescription } from '../mixins'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    // meta: { title: 'ソラシロについて' },
    component: () => import('../views/About.vue')
  },
  {
    path: '/service',
    name: 'service',
    // meta: { title: 'サービス' },
    component: () => import('../views/Service.vue')
  },
  {
    path: '/works/:id?',
    name: 'works',
    // meta: { title: '実績' },
    component: () => import('../views/Works.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    // meta: { title: '技術ブログ' },
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/recruit',
    name: 'recruit',
    // meta: { title: '採用' },
    component: () => import('../views/Recruit.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // meta: { title: 'お問い合わせ' },
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/*',
    component: () => import('../views/Home.vue')
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.commit('setMenuStatus', false)
  globalMixins.methods.setBodyClass('is-menu-open', false)
  // this.setBodyClass(false)
  // setTitle(to.meta.title)
  // setDescription(to.meta.description)
  next()
})

export default router
