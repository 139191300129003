<template>
  <section id="toContact" data-aos="fade-up">
    <h2 title="制作に関するご依頼・ご相談">CONTACT</h2>
    <p>制作に関するご相談・お問い合わせ、お困り事など、アイデア段階でもお気軽にお問い合わせください。</p>
    <div>
      <div>
        <router-link to="/contact" class="btn">お問い合わせ・ご相談フォーム</router-link>
      </div>
      <div>
        <span class="tel" ><img src="../assets/icons/sp.svg" alt="">{{ companyInfo('tel') }}</span>
        <small>平日9:30～18:30<br class="sp">（土日、祝日、年末年始、夏季休暇除く）</small>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'toContact',
  mounted: function () {
    // window.AOS.init()
  },
  computed: {
    ...mapGetters(['companyInfo'])
  }
}
</script>

<style lang="scss" scoped>

#toContact{
  @extend %white_box;
  display: inherit;
  text-align: center;

  h2{
    font-size: 1.7em;
    position: inherit;
    display: block;
    width: auto;
    height: auto;
    top: inherit;
    transform: inherit;
    margin-bottom: 2.4em;

    &:after{
      // font-size: .5em;
      // font-weight: 600;
      line-height: 2.3em;
    }
    &:before{
      display: none;
    }
  }

  >div{
    display: flex;
    justify-content: space-between;
    >div{
      width: 47%;
    }
    .btn{
      @extend %btn;
      font-weight: bold;
      padding: 1em 0;
      width: 100%;
      letter-spacing: .2em;
    }
    .tel{
      display: block;
      text-align: center;
      font-size: 1.9em;
      font-weight: bold;
      img{
        height: 26px;
        margin-top: -0.2em;
        vertical-align: middle;
      }
    }
  }

  // !Mobile
  @include mq-max(){
    >div{
      display: inherit;
      >div{
        width: 100%;
        margin: 1em 0 0 0;
      }
    }
  }
}
</style>
