import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    // baseUrl: '',
    isMobile: false,
    isMenuOpen: false,
    formData: null,
    sclTriggerPC: {
      trigger: '.content',
      start: 'top 40%',
      end: 'top 10%',
      scrub: true,
      markers: false
    },
    sclTriggerSP: {
      trigger: '.content',
      start: 'top 50%',
      end: 'top 30%',
      scrub: true,
      markers: false
    },
    companyInfo: {
      tel: '082-942-5686'
    }
  },
  mutations: {
    setMenuStatus (state, value) {
      state.isMenuOpen = value
    },
    toggleMenuStatus (state) {
      state.isMenuOpen = !state.isMenuOpen
    },
    setIsMobile (state, value) {
      state.isMobile = value
    }
  },
  getters: {
    baseUrl: state => {
      return state.baseUrl
    },
    isMenuOpen: state => {
      return state.isMenuOpen
    },
    scrollTrigger: state => {
      return (state.isMobile) ? state.sclTriggerSP : state.sclTriggerPC
    },
    companyInfo: state => name => {
      return state.companyInfo[name]
    }
  },
  actions: {
  },
  modules: {
  }
})
