<template>
  <div id="menu" v-show="isMenuOpen">
    <Navigation/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'

export default {
  components: {
    Navigation
  },
  computed: {
    isMenuOpen: function () {
      return this.$store.getters.isMenuOpen
    }
  }
}
</script>

<style lang="scss">
#menu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgb(22,124,181);
    background: linear-gradient(180deg, rgba(22,124,181,.9) 22%, rgba(84,185,217,.6) 100%);
    animation: fadeIn .4s linear;
    z-index: 10;
    backdrop-filter: blur(4px);
    nav{
      ul{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        li{
          font-size: 1.2em;
          margin-bottom: 5vh !important;
          &:first-child {
            #pg-home & {
              display: none;
            }
          }
        }
        a {
          color: white;
        }
      }
    }

}

</style>
