<template>
  <div id="app">
    <button class="btn menu" @click="toggleMenu()"></button>
    <Menu/>
    <router-view/>

<a-scene vr-mode-ui="enabled:false" id="scene" device-orientation-permission-ui="enabled:false" embedded>

  <a-assets>
    <img id="backgroundImg" src="./assets/sky/background.jpg" crossorigin="anonymous" alt="">
    <img id="cloud1" src="./assets/sky/cloud2.png" crossorigin="anonymous" alt="">
    <img id="cloud2" src="./assets/sky/cloud3.png" crossorigin="anonymous" alt="">
    <!-- <img id="logoImage" src="./assets/sky/logotype.png" crossorigin="anonymous"> -->
    <img id="test" src="./assets/sky/fog2.png" crossorigin="anonymous">
    <!-- <img id="explosion" src="./assets/sky/explosion_sheet.png" crossorigin="anonymous"> -->
  </a-assets>

  <a-entity id="camera-wrap" position="0 0 0" rotation="0 0 0">
    <a-camera id="camera" look-controls="enabled:false;hmdEnabled:false;" fov="80"></a-camera>
  </a-entity>
  <a-sky src="#backgroundImg" radius="400" phi-start="170"></a-sky>

  <a-entity v-if="!$isMobile()" id="cloud_under" sprite-particles="
  scale: 2000 .. 2500;
  velocity: 0 0 10 .. 0 0 10;
  rotation: 0;
  position: -380 -50 -200 .. 380 -200 -200;
  lifeTime: 5;
  opacity: 0,1,1,0;
  emitterTime: 4000;
  spawnRate: 15;
  texture: #cloud1"></a-entity>

  <a-entity v-if="!$isMobile()" id="cloud_upper1" sprite-particles="
  scale: 1000 .. 1300;
  velocity: 0 0 12 .. 1 0 12;
  rotation: 0..360;
  position: 40 -10 -100 .. 50 20 -100;
  lifeTime: 4;
  opacity: 0,1,1,0;
  emitterTime: 4000;
  spawnRate: .4;
  texture: #cloud2"></a-entity>

  <a-entity v-if="!$isMobile()" id="cloud_upper2" sprite-particles="
  scale: 1000 .. 1300;
  velocity: 0 0 12 .. -1 0 12;
  rotation:0..360;
  position: -40 -10 -100 .. -50 20 -100;
  lifeTime: 4;
  opacity: 0,1,1,1,0;
  emitterTime: 3000;
  spawnRate: .3;
  texture: #cloud2"></a-entity>

  <a-entity v-if="$isMobile()" id="cloud_under" sprite-particles="
  velocity: 0 0 10 .. 0 0 10;
  rotation: 0;
  position: -70 -10 -200 .. 70 -200 -200;
  scale: 1500;
  lifeTime: 5;
  opacity: 0,1,1,0;
  emitterTime: 3000;
  spawnRate: 2;
  texture: #cloud1"></a-entity>

  <a-entity v-if="$isMobile()" id="cloud_upper1" sprite-particles="
  scale: 600;
  velocity: 0 0 12 .. 0 0 12;
  rotation: 0..360;
  position: 30 -10 -100 .. -30 20 -100;
  lifeTime: 4;
  opacity: 0,1,1,0;
  emitterTime: 2000;
  spawnRate: .4;
  texture: #cloud2"></a-entity>

  </a-scene>

    <footer>
      <a href="./"><img src="./assets/logo.svg" alt="sorashiro LLC."></a><br>
      <small>c 2021 sorashiro LLC.</small>
    </footer>
    <div id="cover"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  components: {
    Menu
  },
  metaInfo: {
    titleTemplate: (titleChunk) => {
      // If undefined or blank then we don't need the hyphen
      const siteTitle = 'ソラシロ合同会社 / 広島でUnityを使ったゲーム制作・アプリ制作・インタラクティブコンテンツ開発'
      return titleChunk ? `${titleChunk} - ${siteTitle}` : siteTitle
    }
  },
  mounted: function () {
    this.$store.commit('setIsMobile', this.$isMobile())
  },
  methods: {
    toggleMenu: function () {
      this.setBodyClass('is-menu-open', !this.$store.getters.isMenuOpen)
      this.$store.commit('toggleMenuStatus')
      return false
    }
  }
}
</script>

<style lang="scss">

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

body.is-menu-open{
  overflow-y: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#cover{
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      // -webkit-backdrop-filter: blur(10px);
      // backdrop-filter: blur(10px);
      // background: rgb(85 186 253 / 46%);
      // background: rgba(255,255,255,.7);
      // backdrop-filter: grayscale(50%);
      background: rgba(255, 255, 255, 0.8);
      // background: linear-gradient(180deg, rgba(255,255,255,.9) 0%, rgba(255,255,255,.8) 100%);
      z-index: -1;
      // transition: opacity .3s;
}

.contents {
    margin: 0 auto;

    h1 {
      color: white;
      display: inline-block;
      font-size: 1.9em;
      width: 220px;
      top: 24px;
      left: 24px;
      margin: 0;
      grid-area: Title;
      letter-spacing: .1em;
      border-bottom: 1px solid $hmd_color;
      position: fixed;
      background-position: bottom;
      line-height: 1em;
      padding-left: 4px;

        &:before{
          content: "";
          width: 20px;
          height: 1px;
          border-top: 1px solid $hmd_color;
          transform: rotateZ(45deg);
          position: absolute;
          left: 100%;
          top: 100%;
          transform-origin: left;
        }

        &:after{
            content: attr(title);
            position: absolute;
            top: 100%;
            left: 0;
            font-size: .5em;
            letter-spacing: .1em;
            font-weight: normal;
            padding-left: 4px;
        }

        // !Mobile
        @include mq-max() {
            // position: absolute;
            display: inline-block;
            top: 14px;
            left: 14px;
            padding: 0 .5em 0 0;
            width: auto;

            &:after{
              width: 100%;
              padding-left: 0;
            }
        }
    }

    .intro{
        position: sticky;
        top: 0;
        height: 40vh;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        z-index: -1;
        color: #fff;

        h2{
          font-size: 2.9em;
          letter-spacing: .01em;
          font-weight: normal;
        }

        p{
            margin: 0 auto;
            max-width: 900px;
            font-weight: 500;
            text-align: center;
        }

        // !Mobile
        @include mq-max(){

          padding: 1em;
          background: linear-gradient(rgba(45, 112, 180, 0.7) 65%, rgba(43, 110, 178, 0) 100%);
          height: 50vh;
          h2{
            font-size: 1.65em;
          }
          p{
            font-weight: normal;
            text-align: left;
            font-size: 0.9em;
            line-height: 1.6em;
          }
        }
    }

    h3{
        position: relative;
        font-size: 2.4em;
        line-height: 1.7em;
        margin: 2em 0;
        text-align: left;
        display: inline-block;

        &:nth-of-type(n + 2){
          margin-top: 100px;
        }

        // !Mobile
        @include mq-max(){
          display: block;
          font-size: 1.9em;
          margin: 0 0 1.5em 0;
          text-align: center;
        }
      }

    section{
        width: 80%;
        max-width: 900px;
        margin: 5% auto 140px auto;
        position: relative;
        display: flex;
        justify-content: center;

        @include mq-max(){
          width: 90%;
          margin-bottom: 70px;
          display: inherit;
        }

        h2{
            position: sticky;
            font-size: 1.6em;
            width: 220px;
            height: 1.2em;
            top: 120px;
            display: block;
            text-align: left;
            margin: 0 0 2em;
            padding-left: 2px;
            border-bottom: 1px solid $hmd_color;
            transform: translateX(-14px);
            letter-spacing: .05em;

            &:before{
              content: "";
              width: 15px;
              height: 1px;
              border-top: 1px solid $hmd_color;
              transform: rotateZ(45deg);
              position: absolute;
              left: 100%;
              top: 100%;
              transform-origin: left;
            }

            &:after{
                content: attr(title);
                font-size: .55em;
                font-weight: 500;
                display: block;
                position: absolute;
                letter-spacing: .1em;
                padding-left: 2px;
              }

            // !Mobile
            @include mq-max(){
              position: relative;
              left: auto!important;
              right: auto!important;
              top: 0;
              display: inline-block;
              width: auto;
              transform: none;
              min-width: 46%;
            }
        }

        .content{
            width: 100%;

            @include mq-max(){
              overflow: hidden;
            }
        }

    }
}

button.menu{
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: fixed;
  width: 50px;
  height: 55px;
  right: 15px;
  top: 10px;
  text-align: center;
  z-index: 90;
  line-height: 1em;
  font-size: 0.8em;
  color: #fff;
  text-decoration: none;

  .is-menu-open &{
    color: white!important;
  }

  &:before{
    content: "☰";
    line-height: 1em;
    font-weight: 100;
    font-size: 44px;
    display: block;
    .is-menu-open & {
      content: "×";
      font-weight: 500;
    }
  }

  &:after{
    content: "MENU";
    display: inline-block;
    .is-menu-open & {
      content: "CLOSE";
    }
  }

  #pg-home &{
    color: white!important;
  }

  // !Mobile
  @include mq-max() {
    right: 6px;
    top: 4px;
  }
}

footer{
  text-align: center;
  color: #999;
  font-size: 0.9em;
  letter-spacing: 0.15em;
  opacity: .7;
  padding: 1em;
  text-align: center;
  img{
    width: 35px;
    opacity: .4;
    text-align: center;
    &:hover{
        opacity: 1;
    }
  }

  // !Mobile
  @include mq-max() {
    padding: 0.5em;
    img{
      width: 25px;
    }
  }
}

#pg-blog,
#pg-works{

  .fillter{
    width: 200px;
    margin-right: 20px;
    h2{
      font-size: 1.2em;
      background:none;
      display: block;
      margin-bottom: 2em;
      width: auto;
      height: auto;
      position: relative;
      top: auto;
    }
    .sticky{
      position: sticky;
      top: 120px;
    }
    ul{
      margin: 0;
      padding: 0;
    }
    li{
      list-style: none;
      padding: 3px 0;
      a{
        color: $base-color;
        text-decoration: none;
        display: block;
        border-bottom: 1px solid transparent;
        padding: .2em .5em;
        opacity: .8;
        &:hover{
          opacity: 1;
        }

        &.router-link-exact-active{
          position: relative;
          font-weight: bold;
          opacity: 1;
          // text-shadow: 0 0 5px $hmd_color;
          // border-left: 1px solid $hmd_color;
          // border-bottom: 1px solid $hmd_color;

          // color: $hmd_color;
          // background: $hmd_color;
          // background: linear-gradient(90deg, rgba(87,181,22,1) 70%, rgba(255,255,255,0) 100%);
        }
      }
    }
    // !Mobile
    @include mq-max() {
      width: auto;
      margin-right: 0;
      h2{
        // display: none;
        padding-bottom: 0.4em;
        margin-bottom: 0;
        &:before{
          content: none;
        }
      }
      ul{
        justify-content: space-around;
        margin-bottom: 50px;
        padding: .5em 0 0;
      }
      li{
        display: inline-block;
        padding: 0em .2em;
      }
    }
  }

  .post{
    margin-left: 20px;
    flex: 1;
    // !Mobile
    @include mq-max() {
      margin-left: 0;
      h3{
        display: block;
        &::before{
          content: none;
        }
      }
      p{
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

}

br.sp{
  display: none;
  @include mq-max(){
    display: inline-block;
  }
}

br.no-sp{
  @include mq-max(){
    display: none;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
