<template>
    <div id="messageBox" v-show="this.status">
        <div class="loader" v-show="this.status === 'loading'"></div>
        <div class="msg" v-show="this.message">
          <img src="../assets/icons/error.svg" alt="" v-show="this.status === 'error'">
          <img src="../assets/icons/sended.svg" alt="" v-show="this.status === 'success'">
          <p>{{ this.message }}</p>
          <button class="btn" @click="close">OK</button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MessageBox',
  data () {
    return {
      status: '',
      message: ''
    }
  },
  methods: {
    show (sts, msg) {
      this.status = sts
      this.message = msg
    },
    close () {
      this.status = ''
      this.message = ''
    }
  }
}
</script>

<style lang="scss" scoped>
  #messageBox{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    .msg{
      text-align: center;
      background: #fff;
      max-width: 450px;
      padding: 2em;
      box-shadow: 0 0 40px rgba(0,0,0,.2);
      border: 1px solid $hmd_color;
      p{
        margin-top: 0;
      }
      img{
        width: 160px;
      }
      button{
        @extend %btn;
      }
    }

    @include mq-max() {
      .msg{
        padding: 1em;
        width: 90%;
      }
    }
  }

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.5);
    border-right: 1.1em solid rgba(255, 255, 255, 0.5);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.5);
    border-left: 1.1em solid $hmd_color;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
</style>
