import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export function setBodyClass (className, sw) {
  if (sw) document.body.classList.add(className)
  else document.body.classList.remove(className)
}

export function initGsap (isSP) {
  const h1Option = (isSP) ? {
    opacity: 0,
    scrollTrigger: this.$store.getters.scrollTrigger
  } : {
    color: '#145f7c',
    scrollTrigger: this.$store.getters.scrollTrigger
  }

  gsap.to('h1', h1Option)

  gsap.to('.intro', {
    opacity: 0,
    // scale: 0.95,
    scrollTrigger: this.$store.getters.scrollTrigger
  })

  gsap.to('#cover', {
    opacity: 1,
    scrollTrigger: this.$store.getters.scrollTrigger
  })
  gsap.to('.btn.menu', {
    color: '#145f7c',
    scrollTrigger: this.$store.getters.scrollTrigger
  })
}

export function removeGsap (isSP) {
  const triggers = ScrollTrigger.getAll()
  triggers.forEach(trigger => {
    trigger.kill()
  })
}

export default {

  methods: {
    setBodyClass,
    initGsap,
    removeGsap
  }
}
