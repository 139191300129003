<template>
  <nav class="nav">
    <ul>
      <li><router-link to="/" title="サイトトップ">HOME</router-link></li>
      <li><router-link to="/about" title="ソラシロについて">ABOUT</router-link></li>
      <li><router-link to="/service" title="サービス">SERVICE</router-link></li>
      <li><router-link to="/works" title="実績">WORKS</router-link></li>
      <!-- <li><router-link to="/blog" title="技術ブログ">BLOG</router-link></li> -->
      <li><router-link to="/recruit" title="採用">RECRUIT</router-link></li>
      <li><router-link to="/contact" title="お問い合わせ">CONTACT</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navigation'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  nav{
    ul {
      letter-spacing: -.40em;
      right: 0;
      left: 0;
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;
      padding: 0;

      li {
        position: relative;
        opacity: 0;
        font-weight: 600;

        a {
          color: $base_font_color;
          display: inline-block;
          text-decoration: none;
          padding: 0 1.5em;
          letter-spacing: 1px;
          text-align: center;
          font-size: 1.3em;
          line-height: 1em;

          &:hover {
            background-image: url(../assets/nav_cursor.svg);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            animation: navHover .2s linear;
            text-shadow: 0 0 5px $hmd_color;
            // color: lighten($hmd_color, 5);
            //  animation-fill-mode: backwards;
          }

          &:after{
            content: attr(title);
            display: block;
            font-size: .6em;
            font-weight: normal;
            opacity: .8;
            // margin-top: 0.5em;
          }

        }

        @for $i from 1 through 7 {
          &:nth-child(#{$i}) {
            animation: slideInLeft .3s ease-out #{ 0.05 * $i }s;
            animation-fill-mode: forwards;
          }
        }

      }

    }
  }
</style>
