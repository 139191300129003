import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'

// 共通コンポーネント
import ToContact from './components/toContact.vue'
import MessageBox from './components/MessageBox.vue'
import toTop from './components/toTop.vue'

// import 'aframe'
// import 'aframe-particle-system-component'
import VueSilentbox from 'vue-silentbox'
import VueMeta from 'vue-meta'
import globalMixins from './mixins'
import VueMobileDetection from 'vue-mobile-detection'
import AOS from 'aos'

import './scss/base.scss'
import 'aos/dist/aos.css'

Vue.use(AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom' // defines which position of the element regarding to window should trigger the animation
}))

Vue.config.productionTip = true
Vue.config.ignoredElements = [
  'a-scene',
  'a-assets',
  'a-sky',
  'a-camera',
  'a-cursor',
  'a-animation',
  'a-entity'
]

Vue.use(VueSilentbox)
Vue.use(VueMeta)
Vue.use(VueMobileDetection)
Vue.use(VueAnalytics, {
  id: 'UA-156926789-1',
  router
})

// グローバルコンポーネント登録
Vue.component('ToContact', ToContact)
Vue.component('ToTop', toTop)
Vue.component('MessageBox', MessageBox)

// グローバルメソッドのグローバルミックスイン
Vue.mixin(globalMixins)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
